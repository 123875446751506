@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {


html {
  font-family: 'Merriweather', serif;
}

html {
    scroll-behavior: smooth;
}

/* Chrome, Safari, Edge, Opera */

input.zipcode[type=number]::-webkit-inner-spin-button, input.zipcode[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none; 
  appearance: none; 
}

.tooltip-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.tooltip {
  display: flex;
  align-items: center;
  position: relative;
}
.tooltip .tooltip-trigger {
  display: flex;
  align-items: center;
  background-color: #000;
  color: #000;
  border-radius: 0.375rem;
  cursor: pointer;
}
.tooltip:hover .tooltip-drop {
  opacity: 1;
}
.tooltip:hover .tooltip-drop.tooltip-top, .tooltip:hover .tooltip-drop.tooltip-bottom {
  transform: translate(-50%, 0);
}
.tooltip:hover .tooltip-drop.tooltip-left, .tooltip:hover .tooltip-drop.tooltip-right {
  transform: translate(0, -50%);
}
.tooltip .tooltip-drop {
  position: absolute;
  max-width: 200px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  opacity: 0;
  padding: 0.75rem 0.875rem;
  background-color: #ffffff;
  font-size: 0.875rem;
  text-align: left;
  z-index: 10;
  transition: all 0.15s ease-in-out;
  border-radius: 0.188rem;
  color: #000;
  border-color: #000;
  border-width: 1px;
  pointer-events: none;
}
.tooltip .tooltip-drop::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-top-color: #000;
}
.tooltip .tooltip-drop.tooltip-left, .tooltip .tooltip-drop.tooltip-right {
  top: 50%;
}
.tooltip .tooltip-drop.tooltip-left:before, .tooltip .tooltip-drop.tooltip-right:before {
  top: 50%;
}
.tooltip .tooltip-drop.tooltip-left {
  right: calc(100% + 0.625rem);
  transform: translate(-0.25rem, -50%);
}
.tooltip .tooltip-drop.tooltip-left:before {
  left: 100%;
  transform: translate(0, -50%) rotate(-90deg);
}
.tooltip .tooltip-drop.tooltip-right {
  left: calc(100% + 0.625rem);
  transform: translate(0.25rem, -50%);
}
.tooltip .tooltip-drop.tooltip-right:before {
  right: 100%;
  transform: translate(0, -50%) rotate(90deg);
}
.tooltip .tooltip-drop.tooltip-top, .tooltip .tooltip-drop.tooltip-bottom {
  left: 50%;
  transform: translate(-50%, 0.625rem);
}
.tooltip .tooltip-drop.tooltip-top:before, .tooltip .tooltip-drop.tooltip-bottom:before {
  left: 50%;
}
.tooltip .tooltip-drop.tooltip-top {
  bottom: calc(100% + 0.625rem);
}
.tooltip .tooltip-drop.tooltip-top:before {
  top: 100%;
  transform: translate(-50%, 0);
}
.tooltip .tooltip-drop.tooltip-bottom {
  top: calc(100% + 0.625rem);
}
.tooltip .tooltip-drop.tooltip-bottom:before {
  bottom: 100%;
  transform: translate(-50%, 0) rotate(180deg);
}

.lumos-select{
  outline-color: #054075;
  padding: 10px 16px;
  outline: 1px solid;
  border-right: 16px solid transparent;
}

}